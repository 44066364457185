<template>
  <v-card
      class="mx-auto"
      max-width="1000"
      max-height="1000"
  >
    <v-card-text>

      <div>

        <v-text-field label="Society Logo" v-model="logo"></v-text-field>
        <v-btn @click="updatedSocietyLogo" depressed color="primary">Update</v-btn>
        <v-text-field label="Society Name" v-model="name"></v-text-field>
        <v-btn @click="updatedSocietyName" depressed color="primary">Update</v-btn>
        <v-text-field label="Society E-Mail" v-model="email"></v-text-field>
        <v-btn @click="updatedSocietyEmail" depressed color="primary">Update</v-btn>
        <v-text-field label="Society Description" v-model="description"></v-text-field>
        <v-btn @click="updatedSocietyDescripton" depressed color="primary">Update</v-btn>
        <v-text-field label="Society Whatsapp Link" v-model="whatsappLink"></v-text-field>
        <v-btn @click="UpdateWhatsappLink" depressed color="primary">Update</v-btn>
        <v-text-field label="Society Instagram Link" v-model="instagramLink"></v-text-field>
        <v-btn @click="UpdateInstagramLink" depressed color="primary">Update</v-btn>
      </div>

    </v-card-text>
  </v-card>
</template>
<script>
const axios = require('axios')
import swal from 'sweetalert';

export default {
  props: ["societies"],
  data() {
    return {
      id: null,
      logo: null,
      name: null,
      email: null,
      description: null,
      whatsappLink: null,
      instagramLink: null,

    }
  },
  methods: {
    async updatedSocietyName() {

      this.id = this.$route.params.id;

      this.society = this.societies.data.filter(society => society.ts == this.id)[0];

      const updatedSocietyName = {
        society_name: this.society.society_name,
        new_society_name: this.name

      }
      await axios({
        method: 'put',
        url: "https://tedu-app-prod.lm.r.appspot.com/staff/update-society-name/",
        data: JSON.stringify(updatedSocietyName),
        headers: {
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            this.$router.push({name: 'SocietyPage'});
            swal({
              text: "Society Name Updated Successfully!",
              icon: "success",
              closeOnClickOutside: false,
            });
          })
          .catch(err => console.log(err));
    },
    async UpdateWhatsappLink() {
      const updatedWhatsappLink = {
        society_name: this.name,
        whatsapp_link: this.whatsappLink
      }
      await axios({
        method: 'put',
        url: "https://tedu-app-prod.lm.r.appspot.com/staff/update-society-whatsapp-link/",
        data: JSON.stringify(updatedWhatsappLink),
        headers: {
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            this.$router.push({name: 'SocietyPage'});
            swal({
              text: "Whatsapp Link Updated Successfully!",
              icon: "success",
              closeOnClickOutside: false,
            });
          })
          .catch(err => console.log(err));
    },
    clear() {
      this.id = null;
      this.logo = null;
      this.name = null;
      this.email = null;
      this.description = null;
      this.whatsappLink = null;
      this.instagramLink = null;
    },
    async UpdateInstagramLink() {
      const updatedInstagramLink = {
        society_name: this.name,
        instagram_link: this.instagramLink
      }
      await axios({
        method: 'put',
        url: "https://tedu-app-prod.lm.r.appspot.com/staff/update-society-instagram-link/",
        data: JSON.stringify(updatedInstagramLink),
        headers: {
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            this.clear();
            this.$router.push({name: 'SocietyPage'});
            swal({
              text: "Instagram Link Updated Successfully!",
              icon: "success",
              closeOnClickOutside: false,
            });
          })
          .catch(err => console.log(err));
    },
    async updatedSocietyDescripton() {
      const updatedSocietyDescripton = {
        society_name: this.name,
        description: this.description
      }
      await axios({
        method: 'put',
        url: "https://tedu-app-prod.lm.r.appspot.com/staff/update-society-description/",
        data: JSON.stringify(updatedSocietyDescripton),
        headers: {
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            this.$router.push({name: 'SocietyPage'});
            swal({
              text: "Society Description Updated Successfully!",
              icon: "success",
              closeOnClickOutside: false,
            });
          })
          .catch(err => console.log(err));
    },
    async updatedSocietyEmail() {
      const updatedSocietyEmail = {
        society_name: this.name,
        society_email: this.email
      }
      await axios({
        method: 'put',
        url: "https://tedu-app-prod.lm.r.appspot.com/staff/update-society-email/",
        data: JSON.stringify(updatedSocietyEmail),
        headers: {
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            this.$router.push({name: 'SocietyPage'});
            swal({
              text: "Society Email Updated Successfully!",
              icon: "success",
              closeOnClickOutside: false,
            });
          })
          .catch(err => console.log(err));
    },


    async updatedSocietyLogo() {
      const updatedSocietyLogo = {
        society_name: this.name,
        society_logo: this.logo
      }
      await axios({
        method: 'put',
        url: "https://tedu-app-prod.lm.r.appspot.com/staff/update-society-logo/",
        data: JSON.stringify(updatedSocietyLogo),
        headers: {
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            this.$router.push({name: 'SocietyPage'});
            swal({
              text: "Society Logo Updated Successfully!",
              icon: "success",
              closeOnClickOutside: false,
            });

          })
          .catch(err => console.log(err));
    },


    showInfo() {
      alert(this.name);
    }
  },
  created() {

  },
  mounted() {
    this.id = this.$route.params.id;

    this.society = this.societies.data.filter(society => society.ts == this.id)[0];
    this.name = this.society.society_name;
    this.email = this.society.society_email;
    this.description = this.society.description;
    this.logo = this.society.society_logo;
    this.whatsappLink = this.society.whatsapp_link;
    this.instagramLink = this.society.instagram_link;
  }
}
</script>

<style scoped>

</style>
