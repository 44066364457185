<template>
  <v-item-group>

    <v-container>

      <v-row
          align="center"
          justify="space-around">
        <h1>Society Page</h1>
      </v-row>
   <div class="search-bar">
        <b-form-input
          @input="search_text()"
          v-model="search.text"
          type="text"
          placeholder="Search by Name"
        ></b-form-input>
        <span class="search-icon">
          <i class="fas fa-search"></i>
        </span>
      </div>
 
      
      <v-row>
        <v-col
            v-for="society in societies"
            :key="society.ts"
            cols="12"
            md="4"
        >
        <SingleSociety :society = society></SingleSociety>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script>
//import axios from "axios";

import SingleSociety from "@/components/SingleSociety";
import axios from "axios";

export default {
  components : {SingleSociety},
  data(){
    return{
      societies : [],
       search: { filter: null, text: "" },

    }
  },
  created() {
    this.getSocieties();
  },

  methods: {
    search_text() {
      //console.log(this.search.text);
this.societies = this.societies.filter(function(societies) {
        if (
          societies.name
            .toLowerCase()
            .indexOf(this.search.text.toLowerCase()) != "-1"
        ) {
          return societies;
        }
      });
    },
    getSocieties() {

      var thizz = this;

      axios.get('https://tedu-app-prod.lm.r.appspot.com/staff/get-all-society/')
          .then(function (response) {
            // handle success
            thizz.societies = response.data.data;
            console.log(thizz.societies[0])
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          });
    }
  },
}
</script>


<style scoped>

</style>
