<template>

  <v-card
      class="mx-auto"
      max-width="500"
      max-height="500"

  >
    <v-card-text>
      <div style="display : flex ; text-align: center; justify-items: center">

        <v-toolbar-title>TEDU ADMIN</v-toolbar-title>
      </div>
      <form>
        <v-text-field
            v-model="email"
            label="Email"
        ></v-text-field>
        <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Password"
            @click:append="show1 = !show1"
        ></v-text-field>

        <v-btn
            depressed
            color="primary"
            @click="enter"
        >
          Sign in
        </v-btn>

      </form>
    </v-card-text>
  </v-card>
</template>
<script>

import axios from "axios";
import swal from 'sweetalert';

export default {
  components: {},
  data: () => ({
    password: '',
    email: '',
    isLogin: false,
    show1: false
  }),

  methods: {
    async enter(e) {
      e.preventDefault();
      const params = {
        email: this.email,
        password: this.password,
        admin: true
      }


      await axios({
        method: 'post',
        url: "https://tedu-app-prod.lm.r.appspot.com/auth/login/",
        data: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json'
        }
      })
          .then(res => {
            // login successful, we will get token in res.data object
            localStorage.setItem('token', res.data.token);
            // // redirect to home page
            this.$router.push({name: 'MainPage'});
            swal({
              text: "Login successful. Please continue",
              icon: "success"
            });
          })
          .catch(err => {
            // error handling and showing sweet alert
            swal({
              text: err.response.data.message,
              icon: "error",
              closeOnClickOutside: false,
            });
            console.log(err);
          })

    },

  },

}
</script>
