<template>
  <v-card>
    <v-card-title>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="userList"
        :search="search"
    ></v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  methods: {
    getUsers() {

      var thizz = this;

      axios.get('https://tedu-app-prod.lm.r.appspot.com/staff/get-all-users/')
          .then(function (response) {
            // handle success
            thizz.userList = response.data.data;
            console.log(thizz.userList[0])
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          });
    }
  },
  mounted() {
    this.getUsers();
  },
  data () {
    return {
      search: '',
      userList: [],
      headers: [
        {
          text: 'Display Name',
          align: 'start',
          filterable: true,
          value: 'display_name',
        },
        { text: 'E Mail', value: 'email' },
      ],
    }
  },
}
</script>
