<template>
  <v-card
      class="mx-auto"
      max-width="1000"
      max-height="1000"
  >
    <v-card-text>
 
      <div>

        <v-text-field label="Logo"  v-model="society_logo"></v-text-field>
        <v-text-field label="Society Name"  v-model="society_name"></v-text-field>
        <v-text-field label="Society E-Mail"  v-model="society_email"></v-text-field>
        <v-text-field label="Society Description"  v-model="description"></v-text-field>
        <v-text-field label="Society Whatsapp Link"  v-model="whatsappLink"></v-text-field>
        <v-text-field label="Society Instagram Link"  v-model="instagramLink"></v-text-field>
      </div>

<v-btn @click="addSociety">
        submit
      </v-btn>
    
    </v-card-text>
  </v-card>


</template>


<script>
import axios from "axios";
import swal from 'sweetalert';
export default {
  data(){
    return {
      society_logo : null,
      society_name : null,
      society_email : null,
      description : null,
      whatsappLink : null,
      instagramLink : null,

    
    }
  },
  methods: {
    async addSociety() {
      const newSociety = {
        society_logo : this.society_logo,
        society_name : this.society_name,
        society_email: this.society_email,
        description : this.description,
        whatsapp_link : this.whatsappLink,
        instagram_link : this.instagramLink
      }



      await axios.post('https://tedu-app-prod.lm.r.appspot.com/staff/add-society/', newSociety,
      )
          .then(function (response) {
            console.log(response);
            swal({
              text: " successful. Please continue",
              icon: "success"
            });
          })
          .catch(function(error) {
            console.log(error);
          })


    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.society = this.societies.filter(society => society.id == this.id)[0];
    this.society_name = this.society.society_name;
    this.society_email = this.society.society_email;
    this.description = this.society.description;
    this.society_logo = this.society.society_logo;
    this.whatsappLink = this.society.whatsappLink;
    this.instagramLink = this.society.instagramLink;
  },
   submit () {
    this.$refs.observer.validate()
  },
}
</script>

<style scoped>

</style>
