<template>
  <v-card
      class="mx-auto overflow-hidden"
      height="900"
      width="2000"
  >
    <v-system-bar color="gray darken-3"></v-system-bar>

    <v-app-bar
        color="gray accent-4"
        dark
        prominent
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>TEDU ADMIN</v-toolbar-title>

      <v-spacer></v-spacer>


      <v-btn>
        <router-link
            to="/"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white"
        >Log out
        </router-link
        >
      </v-btn>
    </v-app-bar>


    <v-navigation-drawer
        v-model="drawer"
        absolute
        bottom
        temporary
    >
      <v-list
          nav
          dense
      >
        <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
        >

          <v-list-item>
            <router-link id="listUser" :to="{ name: 'UserTable'}">
              User Table
            </router-link>
          </v-list-item>

          <v-list-item>
            <router-link id="society" :to="{ name: 'SocietyPage'}">
              Society Page
            </router-link>
          </v-list-item>


          <v-list-item>
            <router-link id="disableUser" :to="{ name: 'DisableUser'}">
              Disable User
            </router-link>
          </v-list-item>

          <v-list-item>
            <router-link id="addSociety" :to="{ name: 'AddSociety'}">
              Add Society
            </router-link>
          </v-list-item>
          <v-list-item>
            <router-link id="notification" :to="{ name: 'NotificationPage'}">
              Send Notification
            </router-link>
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-card-text>

    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
  }),
  beforeDestroy() {
    localStorage.removeItem('token');
  },
  methods:{
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
}
</script>

<style scoped>

</style>
