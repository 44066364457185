import { render, staticRenderFns } from "./AddSociety.vue?vue&type=template&id=55ae0a76&scoped=true&"
import script from "./AddSociety.vue?vue&type=script&lang=js&"
export * from "./AddSociety.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55ae0a76",
  null
  
)

export default component.exports