import Vue from 'vue'
import VueRouter from 'vue-router'
import DisableUser from "@/components/DisableUser";
import SocietyPage from "@/components/SocietyPage";
import EditSociety from "@/components/EditSociety";
import MainPage from "@/components/MainPage";
import UserTable from "@/components/UserTable";
import AddSociety from "@/components/AddSociety";
import LoginPage from "@/components/LoginPage";
import NotificationPage from "@/components/NotificationPage";


Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    
    path: '/userTable',
    name: 'UserTable',
    component: UserTable
  },
  {
    path: '/disableUser',
    name: 'DisableUser',
    component: DisableUser
  },
  {
    path: '/SocietyPage',
    name: 'SocietyPage',
    component: SocietyPage
  },
  {
    path: '/EditSociety',
    name: 'EditSociety',
    component: EditSociety
  },
  {
    path: '/MainPage',
    name: 'MainPage',
    component: MainPage
  },
  {
    path: '/AddSociety',
    name: 'AddSociety',
    component: AddSociety
  },
  {
    path: '/NotificationPage',
    name: 'NotificationPage',
    component: NotificationPage
  },

 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
