<template>
  <v-form>
    <v-container>
      <v-row align="center" justify="space-around">
        <h1>Notification Page</h1>
      </v-row>
      <row align="center" justify="space-around">
        <v-row>
          <v-text-field v-model="author" label="Author" required></v-text-field>
        </v-row>
        <v-row>
          <h3>Date Picker</h3>
        </v-row>
        <v-row>
          <v-date-picker v-model="date"></v-date-picker>
        </v-row>

        <v-row>
          <v-text-field v-model="description" :rules="rules.description" label="Description" required></v-text-field>


        </v-row>

        <v-row>
          <v-file-input
              accept="image/png, image/jpeg, image/bmp, image/gif"
              placeholder="Pick an image"
              prepend-icon="mdi-camera"
              v-model="selectedFile"
          ></v-file-input>
        </v-row>
        <v-row>
          <v-text-field v-model="notification_status" label="notification_status" required></v-text-field>
        </v-row>
        <v-row>
          <v-text-field v-model="title" :rules="rules.title" label="Title" required></v-text-field>
        </v-row>

        <v-row align="center" justify="space-around">
          <v-btn depressed color="primary" @click="sendNotification">
            Send Notification
          </v-btn>
        </v-row>
      </row>

    </v-container>

  </v-form>
</template>
<script>
import axios from "axios";
import swal from 'sweetalert';

import {initializeApp} from "firebase/app";
import {getDatabase, ref, push} from "firebase/database";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAYw-TPTxpCo25z-xaZJ7H8rDNjdvfe7g8",
  authDomain: "tedu-app-665f4.firebaseapp.com",
  databaseURL: "https://tedu-app-665f4-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "tedu-app-665f4",
  storageBucket: "tedu-app-665f4.appspot.com",
  messagingSenderId: "881813419908",
  appId: "1:881813419908:web:61b104e5e80f60a5488fd1",
  measurementId: "G-SZG0E6T0EZ"
};

// Initialize Firebase
// eslint-disable-next-line no-unused-vars
const app = initializeApp(firebaseConfig);

export default {
  name: 'App',
  components: {},
  data() {
    return {
      rules: {
        title: [val => (val || '').length > 0 || 'This field is required'],
        description: [val => (val || '').length > 0 || 'This field is required'],
      },
      author: '',
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      description: '',
      image: null,
      notification_status: '',
      title: '',
      selectedFile: null,
      imageUrl: null

    }

  },
  methods: {

    sendNotification() {

      const db = getDatabase();
      const reference = ref(db, 'notifications/');
      let formData = new FormData();
      formData.append('image', this.selectedFile);
      axios.post(
          "https://tedu-app-prod.lm.r.appspot.com/staff/upload/"
          , formData
          , {headers: {"Content-Type": "multipart/form-data"}}
      )
          .then(response => {
            console.log(response);
            push(reference, {
              author: this.author,
              date: this.date,
              description: this.description,
              image: response.data.url,
              notification_status: this.notification_status,
              title: this.title,
              ts: 8640000000000000 -
                  Date.now()

            })
            swal({
              text: "Notification Sent Successfully!",
              icon: "success",
              closeOnClickOutside: false,
            });
          })

    }
  }
}
</script>
