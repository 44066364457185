<template>
  <v-item v-slot="{  }">
    <v-card
        class="mx-auto"
        max-width="400"
    >
      <v-img
          class="white--text align-end"
          height="200px"
          :src="society.society_logo"
      >
      </v-img>
      <v-card-title>{{society.society_name}}</v-card-title>
      <v-card-subtitle class="pb-0">
        <v-card-subtitle class="pb-0">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Description
              </v-expansion-panel-header>
              <v-expansion-panel-content>{{ society.description }}</v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

        </v-card-subtitle>
      </v-card-subtitle>

      <v-card-text class="text--primary">
        <div>{{society.society_email}}</div>

        <a :href="'//'" >
          {{ society.whatsapp_link }}
        </a>
        <a :href="'//'" >
          {{ society.instagram_link }}
        </a>
      </v-card-text>

<v-card-actions class="justify-center">
     <v-btn >
      <router-link id="edit-category" :to="{ name: 'EditSociety', params: { id : society.ts, society: society }}" >
        Edit
      </router-link>
     </v-btn>
     </v-card-actions>
   <!-- <v-btn
                color="blue darken-1"
                text
                @click="deleteSociety"
              >
                Delete
              </v-btn> -->
  

    </v-card>
  </v-item>
</template>


<script>

const axios =  require('axios')


export default {
  props:["society"],
  data(){
    return{
editedIndex: -1,
      editedItem: {},
    }
  },

  methods: {

    
 deleteItem (item) {
      this.editedIndex = this.society.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
      
      const index = this.society[this.editedIndex].name;
      this.deleteSociety(index);
    },
    async deleteSociety() {
      await axios.delete( 'https://tedu-app-prod.lm.r.appspot.com/staff/delete-society/')
          .then(response => {
            console.log(response);
           
          });
    },


  }
}
</script>


<style scoped>

</style>
